"use client";

import { useGlobalStore } from "@/app/context/globalStoreProvider";
import { GondolaLoading } from "@/components/ui/gondolaLoading";
import { UserStatus } from "@/generated/email_parser.openapi";
import { defaultInitialPageRoute } from "@/lib/utils";
import { useSession } from "next-auth/react";
import { useRouter } from "next-nprogress-bar";
import React, { PropsWithChildren, useEffect } from "react";

export const excludedRoutes = [
  "/",
  "/points",
  "/personalized-search",
  "/rate-monitoring",
  "/earn",
];

const PageProtectedAccess: React.FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = React.useState(true);
  const [hasAccess, setHasAccess] = React.useState(false);
  const session = useSession();

  const router = useRouter();

  const { user } = useGlobalStore();

  useEffect(() => {
    if (session.status === "loading") return;
    if (session.status === "unauthenticated") {
      // Redirect to the unsubscribe page if the user is not authenticated with profile settings
      if (
        window.location.pathname === "/profile" &&
        window.location.search.includes("section=settings")
      ) {
        router.replace("/unsubscribe");
      }
      // Exception for the landing page, when the user is not logged in
      if (excludedRoutes.includes(window.location.pathname)) {
        setHasAccess(true);
        setLoading(false);
      }
      return;
    }

    // Check if the user has access to the page based on the user status
    if (session.status === "authenticated" && user?.status) {
      if (
        user.status === UserStatus.SiteAccess &&
        window.location.pathname === "/profile" &&
        window.location.search.includes("section=settings")
      ) {
        router.replace("/unsubscribe");
      } else if (user.status === UserStatus.EmailSynced) {
        if (window.location.pathname === "/") {
          router.replace(defaultInitialPageRoute);
        } else {
          setHasAccess(true);
          setLoading(false);
        }
      } else if (window.location.pathname !== "/onboarding") {
        router.replace("/onboarding");
      }
      return;
    } else {
      router.replace("/");
      return;
    }
  }, [router, session, user]);

  // Show loading spinner while checking user status
  if (loading) {
    return (
      <GondolaLoading containerClassName="h-screen place-content-center" />
    );
  }

  if (hasAccess) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default PageProtectedAccess;
