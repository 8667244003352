"use client";

import {
  lgBreakPoint,
  mdBreakPoint,
  screenInnerWidth,
  xlBreakPoint,
} from "@/lib/utils";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type UIContextType = {
  isLessThanMd: boolean;
  isLessThanLg: boolean;
  isLessThanXl: boolean;
};

const UIContext = createContext<UIContextType>({
  isLessThanMd: false,
  isLessThanLg: false,
  isLessThanXl: false,
});

export default function UIProvider({ children }: PropsWithChildren) {
  const [isLessThanMd, setIsLessThanMd] = useState(
    screenInnerWidth < mdBreakPoint
  );
  const [isLessThanLg, setIsLessThanLg] = useState(
    screenInnerWidth < lgBreakPoint
  );
  const [isLessThanXl, setIsLessThanXl] = useState(
    screenInnerWidth < xlBreakPoint
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLessThanXl(window.innerWidth < xlBreakPoint);
      setIsLessThanLg(window.innerWidth < lgBreakPoint);
      setIsLessThanMd(window.innerWidth < mdBreakPoint);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <UIContext.Provider value={{ isLessThanMd, isLessThanLg, isLessThanXl }}>
      {children}
    </UIContext.Provider>
  );
}

export const useUI = () => {
  const { isLessThanMd, isLessThanLg, isLessThanXl } = useContext(UIContext);

  return { isLessThanMd, isLessThanLg, isLessThanXl };
};
