"use client";

import React, { useState } from "react";
import { useMainLayout } from "@/app/context/mainLayoutProvider";
import { GoogleAuthModal } from "@/components/modal/googleAuthModal";
import WaitlistFormModal from "@/components/modal/waitlistFormModal";
import { Button } from "@/components/ui/button";
import { cn, ppeikoFont } from "@/lib/utils";

const CTASection: React.FC = () => {
  const [googleAuthModalOpen, setGoogleAuthModalOpen] = useState(false);
  const [showWaitlistFormModal, setShowWaitlistFormModal] = useState(false);

  const { isWebView } = useMainLayout();

  const onClick = () => {
    if (isWebView) {
      setShowWaitlistFormModal(true);
    } else {
      setGoogleAuthModalOpen(true);
    }
  };

  return (
    <div className="lg:mx-32">
      <GoogleAuthModal
        headerText="Create an account"
        open={googleAuthModalOpen}
        onOpenChange={setGoogleAuthModalOpen}
      />
      <WaitlistFormModal
        open={showWaitlistFormModal}
        onOpenChange={(val) => {
          if (!val) setShowWaitlistFormModal(false);
        }}
      />
      <div className="mt-10 flex w-full flex-col items-center gap-12 overflow-hidden bg-cover bg-center bg-no-repeat p-6 pt-[75px] lg:p-10 lg:pt-28">
        <span className="text-center text-4xl font-normal capitalize leading-10 text-primary lg:text-[58px] lg:leading-[63.80px]">
          <span>Travel Upgraded With </span>
          <span
            className={cn(
              "font-medium italic text-primary-gold",
              ppeikoFont.className
            )}
          >
            Gondola
          </span>
        </span>
        <div className="z-10">
          <Button
            className="h-16 rounded-full bg-primary-gold !px-8"
            onClick={onClick}
            v2Effect
          >
            <img
              src="/assets/3-stars-gold-black.svg"
              alt="3-stars-gold-black"
              className="h-6 w-6"
            />
            Get Started
          </Button>
        </div>
        <img
          src="/assets/landing-page/cta-background.svg"
          alt="bg"
          className="z-0 -mb-[30%] -mt-[20%] w-full lg:-mt-[25%]"
        />
      </div>
    </div>
  );
};

export default CTASection;
