"use client";

import { useEffect, useState } from "react";
import { cn, isValidEmail, ppeikoFont } from "@/lib/utils";
import { useMainLayout } from "@/app/context/mainLayoutProvider";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import useLandingPageInvite from "@/hooks/client/useLandingPageInvite";
import { GoogleAuthModal } from "@/components/modal/googleAuthModal";
import { motion } from "motion/react";
import { useUI } from "@/app/context/UIProvider";

const postFixTexts = [
  "All Your Points",
  "Every price drop",
  "Cash and Points",
  "Maximizing rewards",
];

const Hero: React.FC = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [googleAuthModalOpen, setGoogleAuthModalOpen] = useState(false);
  const [selectedPostFixText, setSelectedPostFixText] = useState(
    postFixTexts[0]
  );

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % postFixTexts.length;
      setSelectedPostFixText(postFixTexts[currentIndex]);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const { isWebView } = useMainLayout();

  const { joinWaitlist, loading, submitted } = useLandingPageInvite();

  const isValid = isValidEmail(emailAddress);

  const { isLessThanLg } = useUI();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8 lg:flex-row">
      <GoogleAuthModal
        headerText="Create an account"
        open={googleAuthModalOpen}
        onOpenChange={setGoogleAuthModalOpen}
      />
      <div className="mt-6 flex w-full flex-col items-center gap-10">
        {isLessThanLg ? (
          <div className="relative w-full">
            <img
              src="/assets/landing-page/hero.png"
              alt="Hero"
              className="h-[180px] w-full object-cover"
            />
            <div className="mx-5 flex flex-col items-center gap-4 px-4 py-4 pb-7 lg:mx-28">
              <div className="flex flex-col items-center gap-1">
                <div className="text-center text-[32px] font-normal leading-9 text-primary">
                  One Place For
                </div>
                <motion.div
                  className={cn(
                    "text-center text-[32px] font-medium capitalize italic leading-9 text-primary-gold",
                    ppeikoFont.className
                  )}
                  transition={{ duration: 1 }}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -20, opacity: 0 }}
                  key={selectedPostFixText}
                >
                  {selectedPostFixText}
                </motion.div>
              </div>
              <div className="w-full text-center font-normal leading-[21px] text-primary">
                Gondola automatically tracks your points, finds the best hotel
                deals, and ensures you earn more on every booking.
              </div>
              {isWebView ? (
                <div className="justify-citems-center flex w-full flex-col items-center gap-4 rounded-b-2xl">
                  <Input
                    className="flex h-14 items-center justify-start gap-2 rounded-xl border border-white border-opacity-10 text-[15px] lowercase placeholder:normal-case"
                    placeholder="Enter your email"
                    value={emailAddress}
                    onChange={(e) =>
                      setEmailAddress(e.target.value.toLowerCase())
                    }
                    disabled={submitted}
                    type="email"
                  />
                  <Button
                    onClick={() => joinWaitlist(emailAddress)}
                    disabled={submitted || !isValid || loading}
                    className="h-14 w-full whitespace-nowrap"
                    variant="3d-gold"
                  >
                    {loading ? (
                      <Loader2 className="h-5 w-5 animate-spin" />
                    ) : (
                      "Get Started"
                    )}
                  </Button>
                  {submitted ? (
                    <p className="-mb-1 mt-2 w-full text-center text-primary">
                      You&apos;re almost there! Check your inbox to confirm your
                      email.
                    </p>
                  ) : null}
                </div>
              ) : (
                <Button
                  variant="3d-gold"
                  className="h-16 w-full rounded-b-full bg-primary-gold !px-8"
                  onClick={() => setGoogleAuthModalOpen(true)}
                >
                  Get Started
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="relative h-[600px] w-full">
            <img
              src="/assets/landing-page/hero.png"
              alt="Hero"
              className="h-[600px] w-full rounded-3xl object-cover shadow-2xl filter"
            />
            <div className="absolute bottom-5 left-0 right-0 flex w-full justify-center text-sm font-normal leading-none text-grey-4">
              The Gritti Palace, a Luxury Collection Hotel, Venice
            </div>
            <div className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transform">
              <div className="flex flex-col items-center gap-8">
                <div className="flex w-full flex-col justify-center gap-4 text-center xl:flex-row">
                  <div className="text-center text-[58px] font-normal leading-[63.80px] text-primary">
                    One Place For{" "}
                  </div>
                  <motion.div
                    className={cn(
                      "text-center text-[58px] font-medium capitalize italic leading-[63.80px] text-primary-gold",
                      ppeikoFont.className
                    )}
                    transition={{ duration: 1 }}
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -20, opacity: 0 }}
                    key={selectedPostFixText}
                  >
                    {selectedPostFixText}
                  </motion.div>
                </div>
                <div className="mx-10 w-[780px] text-center text-[28px] font-normal leading-[42px] text-primary lg:mx-24">
                  Gondola automatically tracks your points, finds the best hotel
                  deals, and ensures you earn more on every booking.
                </div>
                <Button
                  variant="3d-gold"
                  className="h-16 rounded-full bg-primary-gold !px-8"
                  onClick={() => setGoogleAuthModalOpen(true)}
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hero;
