"use client";

import { checkIfUserIsOnWaitlist } from "@/components/modal/waitlistFormModal/serverActions";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { handleError } from "@/lib/apiUtils";
import { useRouter, useSearchParams } from "next/navigation";
const useLandingPageInvite = () => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const posthog = usePostHog();
  const router = useRouter();
  const searchParams = useSearchParams();

  const joinWaitlist = async (emailAddress: string) => {
    try {
      setLoading(true);

      // Extract and clean all query parameters from the URL
      const sourceParams: Record<string, string> = {};
      searchParams.forEach((value, key) => {
        // Clean up keys that might have encoding issues
        const cleanKey = key.replace(/^\?/, ""); // Remove leading ? if present
        sourceParams[cleanKey] = value;
      });

      const response = await fetch("/api/user/join_waitlist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email_address: emailAddress,
          source_params: sourceParams,
        }),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        posthog.identify(emailAddress);
        router.push("/onboarding?webview=true");
      } else {
        throw result.error || new Error("Unknown error occurred");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    (async () => {
      const isOnWaitlist = await checkIfUserIsOnWaitlist();
      if (isOnWaitlist) {
        setSubmitted(true);
      }
    })();
  }, []);

  return {
    submitted,
    loading,
    joinWaitlist,
  };
};

export default useLandingPageInvite;
