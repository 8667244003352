"use client";

import { excludedRoutes } from "@/components/section/pageProtectedAccess/PageProtectedAccess";
import { cn } from "@/lib/utils";
import { usePathname } from "next/navigation";
import React, { PropsWithChildren } from "react";

const hideFooterPaths = [
  ...excludedRoutes,
  "/waitlist",
  "/profile",
  "/auth/signin",
  "/auth/error",
  "/hotel/search/researcher",
];

const FooterWrapper: React.FC<
  PropsWithChildren<{
    bypassHiddenCondition?: boolean;
  }>
> = ({ children, bypassHiddenCondition }) => {
  const pathname = usePathname();
  return (
    <footer
      className={cn(
        "flex w-full flex-col pb-24 pt-14",
        hideFooterPaths.includes(pathname) && !bypassHiddenCondition
          ? "hidden"
          : ""
      )}
    >
      {children}
    </footer>
  );
};

export default FooterWrapper;
